export default [
  {
    title: 'menu-deliveries-list',
    route: 'deliveries-list',
    icon: 'ListIcon',
    resource: 'deliveries',
    action: 'list'
  },
  {
    title: 'menu-deliveries-print',
    icon: 'ListIcon',
    route: {
      path: '/deliveries/create#/print'
    },
    resource: 'deliveries',
    action: 'create'
  },
  {
    title: 'menu-returns',
    icon: 'CornerUpLeftIcon',
    resource: 'menu',
    action: 'returns',
    children: [
      {
        title: 'menu-returns-create',
        route: 'returns-create',
        resource: 'returns',
        action: 'create'
      },
      {
        title: 'menu-returns-list',
        route: 'returns-list',
        resource: 'returns',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-tickets-panel',
    icon: 'FileTextIcon',
    route: 'tickets-madagascar',
    resource: 'tickets',
    action: 'detail'
  },
  {
    title: 'menu-support',
    icon: 'HelpCircleIcon',
    route: 'support',
    resource: 'support',
    action: 'info'
  },
  {
    title: 'menu-claims',
    icon: 'BookIcon',
    href: 'https://forms.gle/Nmu4HSadabAi1yUF6',
    resource: 'common',
    action: 'manage'
  }  
]
