export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'menu',
    action: 'dashboard'
  },
  {
    title: 'menu-reports',
    icon: 'BarChartIcon',
    route: 'automatic-reports',
    resource: 'automatic-reports',
    action: 'list'
  },
  {
    title: 'menu-deliveries',
    icon: 'SendIcon',
    children: [
      {
        title: 'menu-deliveries-create',
        route: 'deliveries-create',
        resource: 'deliveries',
        action: 'create'
      },
      {
        title: 'menu-deliveries-list',
        route: 'deliveries-list',
        resource: 'deliveries',
        action: 'list'
      },
      {
        title: 'menu-deliveries-summaries',
        route: {
          path: '/deliveries/create#/summaries'
        },
        resource: 'deliveries',
        action: 'create'
      }
    ]
  },
  {
    title: 'menu-deliveries-flash',
    icon: 'ZapIcon',
    resource: 'menu',
    action: 'envia-flash',
    children: [
      {
        title: 'menu-deliveries-flash-create',
        route: 'envia-flash',
        resource: 'envia-flash',
        action: 'create'
      },
      {
        title: 'menu-deliveries-flash-config',
        route: 'envia-flash',
        resource: 'envia-flash',
        action: 'config'
      }
    ]
  },
  {
    title: 'menu-returns',
    icon: 'CornerUpLeftIcon',
    resource: 'menu',
    action: 'returns',
    children: [
      {
        title: 'menu-returns-create',
        route: 'returns-create',
        resource: 'returns',
        action: 'create'
      },
      {
        title: 'menu-returns-list',
        route: 'returns-list',
        resource: 'returns',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-marketcenter',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'config-marketcenter',
        route: 'marketcenter',
        resource: 'marketcenter',
        action: 'config'
      },
      {
        title: 'menu-marketcenter-deliveries',
        route: 'marketcenter-deliveries',
        resource: 'marketcenter',
        action: 'deliveries'
      },
      {
        title: 'menu-marketcenter-products',
        route: 'marketcenter-products',
        resource: 'marketcenter',
        action: 'products'
      },
      {
        title: 'menu-marketcenter-labels',
        route: 'marketcenter-labels',
        resource: 'marketcenter',
        action: 'labels'
      }
    ]
  },
  {
    title: 'menu-carrier',
    icon: 'PackageIcon',
    route: {
      path: '/carriers-client/:id'
    },
    resource: 'carriers',
    action: 'carrier-client'
  },  
  {
    title: 'menu-tickets',
    icon: 'FileTextIcon',
    resource: 'menu',
    action: 'tickets',
    children: [
      {
        title: 'menu-tickets-pending',
        route: 'tickets-pending',
        resource: 'tickets',
        action: 'pending'
      },
      {
        title: 'menu-tickets-all',
        route: 'tickets-list', // OJO esto se supone q no se migrara porq ya esta panel
        resource: 'tickets',
        action: 'all'
      },
      {
        title: 'menu-tickets-assignments',
        route: 'assign-user',
        resource: 'tickets',
        action: 'assignments'
      },
      {
        title: 'menu-tickets-panel',
        route: 'tickets-madagascar',
        resource: 'tickets',
        action: 'detail'
      }
    ]
  },
  {
    title: 'menu-rules-tickets',
    icon: 'ClipboardIcon',
    resource: 'menu',
    action: 'rule-tickets',
    children: [
      {
        title: 'menu-rules-tickets-list',
        route: 'tm-rule-list',
        resource: 'rule-tickets',
        action: 'list'
      },
      {
        title: 'menu-rules-tickets-create',
        route: 'tm-rule-create',
        resource: 'rule-tickets',
        action: 'create'
      },
      {
        title: 'menu-rules-tickets-group-list',
        route: 'tm-group-list',
        resource: 'rule-tickets',
        action: 'groups'
      },
      {
        title: 'menu-rules-tickets-group-create',
        route: 'tm-group-create',
        resource: 'rule-tickets',
        action: 'create-group'
      }
    ]
  },
  {
    title: 'menu-rules',
    icon: 'SlidersIcon',
    route: 'advanced-rules-list',
    tag: 'beta',
    resource: 'advanced-rules',
    action: 'list'
  },
  {
    title: 'menu-materials',
    icon: 'ArchiveIcon',
    route: 'materials',
    resource: 'materials',
    action: 'list'
  },
  {
    title: 'menu-marketplace',
    icon: 'SmartphoneIcon',
    route: {
      path: '/companies/beta/:id'
    },
    resource: 'companies',
    action: 'details'
  },
  {
    title: 'menu-companies',
    icon: 'TabletIcon',
    route: 'companies',
    resource: 'companies',
    action: 'list'
  },
  {
    title: 'menu-cash',
    icon: 'FileIcon',
    route: {
      path: '/recaudos/:id'
    },
    resource: 'recaudos',
    action: 'list'
  },
  {
    title: 'menu-pricing-tariff',
    icon: 'DollarSignIcon',
    route: 'tariff-calculator',
    resource: 'pricing',
    action: 'calculator'
  },
  {
    title: 'menu-support',
    icon: 'HelpCircleIcon',
    route: 'support',
    resource: 'support',
    action: 'info'
  },  
  {
    title: 'menu-billing',
    icon: 'FileIcon',
    route: {
      path: '/billing/details/:id'
    },
    resource: 'billing',
    action: 'details'
  }
]
