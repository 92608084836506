<template>
	<div>
		<b-modal id="modalSearchDeliveries" scrollable size="xl" modal-class="custom-dialog"
    hide-footer no-close-on-backdrop :title="$t('Envíos')" @show="onShow">
    <table-render id="table-search-deliveries" :schema="schema" :rows.sync="rows" class="mx-1" :key="keyTableRender">
     <template #current_status="scope">
       <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status.id]}`"></span> {{scope.rowdata.status.name}}
     </template>
     <template #imported_id="scope">
       <router-link class="p-0" 
          :to="{ name: scope.rowdata.type === 'Envío' ? 'return-details' : 'deliveries-details', params: { id: scope.rowdata.imported_id } }">
        {{ scope.rowdata.imported_id }}
      </router-link>
     </template>
      <template #place_name="scope">
        <a v-b-tooltip.click.html="scope.rowdata.place_information" class="lnk underline-text">{{scope.rowdata.shipping_address.place}}</a>
      </template>
      <template #pickup_date="scope">
        {{scope.rowdata.pickup_date || "sin info."}}
      </template>
      <template #created_at="scope">
        <a v-b-tooltip.click.html="scope.rowdata.created_at_hour" class="lnk underline-text">{{scope.rowdata.created_at}}</a>
      </template>
    </table-render>
		</b-modal>
	</div>
</template>

<script>

export default {
  props: ['data'],
  data () {
    return {
      schema: [],
      rows: [],
      keyTableRender: 0
    }
  },
  watch: {
    data () {
      console.log('data', this.data)
      this.rows = this.data
      this.keyTableRender++
    }
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: this.$t('Estado'), key: 'current_status', useSlot: true},
        {label: this.$t('Tipo'), key: 'type'},
        {label: this.$t('N. de envío'), key: 'imported_id', useSlot: true, wrap: true},
        {label: this.$t('Orden de flete'), key: 'tracking_number', wrap: true},
        {label: this.$t('Cliente'), key: 'customer_name'},
        {label: this.$t('Destino'), key: 'destination'},
        {label: this.$t('Comuna'), key: 'place_name', useSlot: true},
        {label: this.$t('Courier'), key: 'carrier'},
        {label: this.$t('Fecha creación'), key: 'created_at', useSlot: true, wrap: true},
        {label: this.$t('Fecha compromiso'), key: 'deadline_at', wrap: true}
      ]
      if (['marketplace', 'superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        this.schema.unshift({label: this.$t('Empresa'), key: 'company_name'})
      }
      console.log('init', this.rows, this.data)
    },
    onShow() {
      this.setInitialData()
    }
  }
}
</script>
<style>
a.underline-text{
  text-decoration: underline !important;
}
</style>
