<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <!-- <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a> -->

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <button @click="getDeliveries" :class="disabledSearch ? 'disabled-search text-secondary' : 'text-primary'" :disabled="disabledSearch || loading">
          <feather-icon v-if="!loading" icon="SearchIcon"/>
          <b-spinner v-else small></b-spinner>
        </button>        
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Buscar por OT o número de envío"
        autofocus
        autocomplete="off"
        @keyup.enter="getDeliveries"
      />
      <div
        class="search-input-close"
        @click="resetsearchQuery"
        v-if="!disabledSearch"
      >
        <feather-icon v-if="!loading" icon="XIcon" />
      </div>
    </div>
    <div>
      <button class="modal-search-button" @click="$bvModal.show('modalMultiSearch')">
        <feather-icon icon="ChevronDownIcon"/>
      </button>  
    </div>
    <modal-multi-search @filter="collectFullFilter" ref="refModalSearch"/>
    <modal-search-deliveries :data="searchData"/>
  </li>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import BaseServices from '@/store/services/index'
import store from '@/store'
import ModalMultiSearch from '@/views/modules/deliveries/create/generate-labels/ModalMultiSearch.vue'
import ModalSearchDeliveries from './ModalSearchDeliveries.vue'

export default {
  components: {
    BFormInput,
    ModalMultiSearch,
    ModalSearchDeliveries
  },
  data () {
    return {
      showSearchBar: true,
      disabledSearch: true,
      perfectScrollbarSettings: { maxScrollbarLength: 60 },
      baseService: new BaseServices(this),
      searchQuery: null,
      filteredData: null,
      currentSelected: -1,
      loading: false,
      searchData: []
    }
  },
  watch: {
    searchQuery() {
      store.commit('app/TOGGLE_OVERLAY', Boolean(this.searchQuery))
      this.disabledSearch = !this.searchQuery
    }
  },
  methods: {
    getDeliveries() {
      if (!this.validateSearch()) return
      this.loading = true
      const queryParams = {
        number: this.searchQuery
      }
      this.baseService.callService('filterDeliveryByTag', queryParams)
        .then(data => {
          const total = data.response.length
          if (!total) {
            this.$bvToast.toast(`Los siguientes envíos no se encontraron: ${this.searchQuery}`, {
              title: this.$t('¡Oops!'),
              variant: 'info',
              solid: true
            })
          } else if (total === 1) {
            const nameRoute = data.response[0].type === 'return' ? 'return-details' : 'deliveries-details'
            const identifier = data.response[0].identifier
            this.$router.push({ name: nameRoute, params: { id: identifier } })
          } else {
            this.searchData = data.response.map(data => ({
              ...data,
              id: data.identifier,
              company_name: data.company?.name,
              type: data.type === 'return' ? 'Devolución' : data.type === 'delivery' ? 'Envío' : 'Pudo',
              tracking_number: data.tracking_number || 'Sin info.',
              customer_name: data.customer?.full_name || 'Sin info.',
              destination: !data.shipping_address?.type === 'pudo' ? 'Domicilio' : 'Punto de entrega',
              place_information: `${data.shipping_address?.full_address || ''}, ${data.shipping_address.place || ''}, ${data.country_name || ''}`,
              deadline_date: data.deadline_date || 'No informada.'
            }))
            this.$bvModal.show('modalSearchDeliveries')
          }
          // Manejar los datos obtenidos
        })
        .catch(error => {
          console.log('error', error)
          // Manejar errores
        })
        .finally(() => {
          store.commit('app/TOGGLE_OVERLAY', false)
          this.loading = false
        })
    },
    validateSearch() {
      if (this.searchQuery.search(/[<>/]/g) !== -1) {
        this.$bvToast.toast('El campo Busqueda contiene caracteres no permitidos.', {
          title: this.$t('¡Oops!'),
          variant: 'danger',
          solid: true
        })
        this.searchQuery = null
        return false
      }
      return true
    },
    resetsearchQuery() {
      if (this.loading) return
      this.searchQuery = null
    },
    collectFullFilter(data) {
      this.searchQuery = data.number
      this.$bvModal.hide('modalMultiSearch')
      this.getDeliveries()
    }
  },
  mounted() {
  }
  // setup() {
  //   const showSearchBar = ref(true)
  //   const disabledSearch = ref(true)
  //   const perfectScrollbarSettings = { maxScrollbarLength: 60 }
  //   const baseService = new BaseServices(this)
  //   const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({ data: searchAndBookmarkData, searchLimit: 4 })
  //   const currentSelected = ref(-1)
  //   const refModalSearch = ref(null)

  //   watch(searchQuery, val => {
  //     store.commit('app/TOGGLE_OVERLAY', Boolean(val))
  //     disabledSearch.value = !val
  //   })

  //   onMounted(() => {
  //     refModalSearch.value = this.$refs.refModalSearch
  //   })

  //   const getDeliveries = () => {
  //     const queryParams = {
  //       number: searchQuery.value
  //     }
  //     baseService.callService('filterDeliveryByTag', queryParams)
  //       .then(data => {
  //         console.log('data', data)
  //         // Manejar los datos obtenidos
  //       })
  //       .catch(error => {
  //         console.log('error', error)
  //         // Manejar errores
  //       })
  //   }
    
  //   const collectFullFilter = (data) => {
  //     console.log('collectFullFilter', data, refModalSearch.value, refModalSearch, this.$refs.refModalSearch)
  //     searchQuery.value = data.number
  //     refModalSearch.value.resetForm()
  //     getDeliveries()
  //   }

    

  //   return {
  //     showSearchBar,
  //     perfectScrollbarSettings,
  //     searchAndBookmarkData,
  //     title,
  //     currentSelected,
  //     searchQuery,
  //     resetsearchQuery,
  //     filteredData,

  //     baseService,
  //     getDeliveries,
  //     disabledSearch,
  //     collectFullFilter
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}

.disabled-search {
  color: #868686;
  cursor: not-allowed;
}
</style>
