<template>
    <b-modal 
        id="ModalCustomLogin" 
        title="Inicio de Sesión Personalizado" 
        modal-class="dialog-600" 
        ok-only 
        no-close-on-esc 
        no-close-on-backdrop 
        @close="close"
        centered
        hide-footer>
        <form-render
            :buttonSend="buttonSend"
            @send="getUserToken"
            :form.sync="form"
            :fields="fields"
            containerButtonsClass="col-sm-12 d-flex justify-content-end"
        />
    </b-modal>
</template>
<script>
import Cookies from 'js-cookie'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
export default {
  name: 'custom-login',
  data () {
    return {
      buttonSend: {color: 'warning', text: 'Iniciar sesión', disabled: false},
      form: {},
      fields: []
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    close () {
      this.form = {}
    },
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: 'E-mail', containerClass: 'col-sm-12 container-info', validation: 'required|email' }
      ]
    },
    getUserToken (data) {
      this.buttonSend.disabled = true
      const payload = {
        email: data.email
      }
      this.$store.dispatch('fetchService', {
        name: 'postCustomToken',
        queryParams: payload,
        onSuccess: (resp) => this.sucessTokenCreated(resp.data),
        onError: (err) => this.errorTokenCreated(err),
        hideAlert: true
      })
    },
    sucessTokenCreated (data) {
      this.customLogin(data.token)
    },
    errorTokenCreated (err) {
      this.buttonSend.disabled = false
      this.$alert(this.$t('msg-problema-obtener-token', { code: err }))
    },
    customLogin (token) {
      this.$root.$data.auth
        .signInWithCustomToken(token)
        .then((data) => {
          this.clearSession()
          this.$root.$data.auth.setPersistence(this.$root.$data.fb.auth.Auth.Persistence.LOCAL)
          this.loggedUser = data.user
          return data.user.getIdToken()
        })
        .then((token) => {
          this.buttonSend.disabled = false
          this.$bvModal.hide('ModalCustomLogin')
          this.$store.dispatch('fetchUserData', {id: this.loggedUser.uid, token, from: 'custom-token', remember: true})
        })
        .catch((error) => {
          this.buttonSend.disabled = false
          this.$alert(this.$t('msg-problema-iniciar-sesion', { code: error }))
        })
    },
    clearSession () {
      Cookies.remove('session_id')
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('session_id')
      this.$ability.update(initialAbility)
    }
  }
}
</script>